<!--
 * @Author: wyq
 * @Date: 2021-09-27 09:20:54
 * @LastEditTime: 2022-04-15 16:22:01
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\testingCenter\startErrorTesting.vue
-->
<template>
  <el-container class="start-testing round-bg">
    <el-header>
      <div class="testing-header">
        <div class="d-flex">
          <sequence-bar class="flex-grow-1" inline :currentIndex="currentQuestionIndex" :value="questions">
          </sequence-bar>
          <div class="flex-right">
            <v-btn outlined color="error" @click="finishExamination">结束测试</v-btn>
          </div>
        </div>
        <div class="progress-bar">
          <div class="slider" :style="{ width: sliderWidth }"></div>
        </div>
      </div>
    </el-header>
    <el-main>
      <question-container ref="questionContent" :questionInfo="currentQuestion" :loading="loading"
        @submitAnswer="submitAnswer"></question-container>
    </el-main>
  </el-container>
</template>
<script>
import sequenceBar from '@/components/testing/sequenceBar.vue'
import questionContainer from '@/components/testing/questionContainer'
export default {
  components: {
    sequenceBar,
    questionContainer,
  },
  data() {
    return {
      show: true,
      fillupAnswer: '',
      examinationId: null,
      questions: [],
      currentQuestionIndex: 0,
      timers: {},
      loading: false,
    }
  },
  computed: {
    sliderWidth() {
      return (
        parseInt((this.currentQuestionIndex * 100) / this.questions.length) +
        '%'
      )
    },
    currentQuestion() {
      if (this.questions.length) {
        return this.questions[this.currentQuestionIndex]
      } else {
        return {}
      }
    },
    testingType() {
      return this.$route.query.type
    },
  },
  methods: {
    init() {
      this.timers = this.$refs.questionContent.getTimer()
      console.log(this.timers)
      this.startExamination()
    },
    async startExamination() {
      try {
        let res
        if (this.testingType == 'train') {
          let params = {
            id: Number(this.$route.query.id),
          }
          res = await this.$service.startTrainExamination(params)
        } else if (this.testingType == 'error') {
          res = await this.$service.startErrorExamination()
        } else if (this.testingType == 'teacher') {
          let params = {
            trainNodeId: Number(this.$route.query.id),
            trainTopNodeId: Number(this.$route.query.topId),
          }
          res = await this.$service.startFamousTeacherTrain(params)
        }
        this.examinationId = res.examinationId
        this.questions = res.questions.map((item) => this.$formattedData(item))
        this.timers.currentTimer.start()
        this.timers.totalTimer.start()
      } catch (err) {
        // this.$router.go(-1)
      }
    },
    async submitAnswer(answer) {
      this.timers.currentTimer.stop()
      this.timers.totalTimer.stop()
      this.loading = true

      let params = {
        examinationId: this.examinationId,
        questionId: this.currentQuestion.questionId,
        answer: answer,
      }
      try {
        if (this.testingType == 'train') {
          await this.$service.submitTrainAnswer(params)
        } else if (this.testingType == 'error') {
          await this.$service.submitErrorAnswer(params)
        } else if (this.testingType == 'teacher') {
          await this.$service.submitFamousTeacherQuestion(params)
        }
        this.loading = false
        this.currentQuestionIndex++
        if (this.currentQuestionIndex >= this.questions.length) {
          this.finishExamination()
        } else {
          this.timers.currentTimer.reset()
          this.timers.currentTimer.start()
          this.timers.totalTimer.start()
        }
      } catch (error) {
        this.$message.warning({
          message: '网络异常，请重新尝试',
        })
        this.loading = false
      }
    },
    async finishExamination() {
      try {
        if (this.currentQuestionIndex == 0) {
          this.$router.go(-1)
          return
        }
        let params = {
          examinationId: this.examinationId,
        }
        if (this.testingType == 'train') {
          await this.$service.finishTrainExamination(params)
        } else if (this.testingType == 'error') {
          await this.$service.finishErrorExamination(params)
        }
        this.$router.push({
          name: 'ErrorReport',
          query: {
            type: this.testingType,
            examinationId: this.examinationId,
          },
        })
      } catch {
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.start-testing {
  .testing-header {
    background-color: #fff;

    .flex-right {
      min-width: 185px;
      padding: 14px 44px;
      border-left: 1px solid #fafafa;
    }

    .progress-bar {
      height: 8px;
      background: #eeeeee;

      .slider {
        width: 30%;
        height: 8px;
        background: #0148ff;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  .el-main {
    padding: 12px;
  }
}
</style>